/* You can add global styles to this file, and also import other style files */
html {
    font-size: 14px;
    line-height: 1.2; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    -o-tab-size: 4;
       tab-size: 4; /* 3 */
    font-family: Roboto, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif; /* 4 */
    font-feature-settings: normal; /* 5 */
    font-variation-settings: normal; /* 6 */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.warning-text {
    color: #c83e4d;
    font-weight: bold;
}

.supplementary-text {
    color: rgb(155, 155, 155);
    font-size: 0.9rem;
}

.buttons app-button {
    margin-left: 0.5rem;
}